@import "./constants.scss";

.invoiceRow {
  .invoiceTh {
    font-weight: normal;
    padding-left: 0 !important;
    text-transform: uppercase;
    font-size: 0.625rem;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    span {
      color: $gray;
      font-style: italic;
      text-transform: none;
      font-size: 0.5rem;
    }
  }
  .invoiceTd {
    padding-right: 0 !important;
    font-size: 0.75rem;
    font-weight: 500;
    text-align: right;
    color: $boomerangBlue;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  &:first-child {
    td,
    th {
      border-top: 0 none !important;
      vertical-align: middle;
    }
  }
  .total {
    color: $darkBlue;
  }
  .due {
    color: $orange;
  }
  .retainage {
    color: $gray;
  }
}
.invoicePrint {
  flex-grow: 0.5;
  padding-right: 10px;
}
@import "./constants.scss";

.map-section {
  padding-right: 0;
}

.map {
  height: 275px;
  overflow: hidden;

  &.small {
    width: 110px;
    height: 80px;
  }
}

.marker-blue path {
  fill: $boomerangBlue;
}

.marker-dark-blue path {
  fill: $darkBlue;
}

.marker-blue-green path {
  fill: $blueGreen;
}
.marker-dark-blue-green path {
  fill: $darkBlueGreen;
}
.marker-green path {
  fill: $green;
}
.marker-dark-green path {
  fill: $darkGreen;
}
.marker-yellow path {
  fill: $yellow;
}
.marker-yellow-orange path {
  fill: $yellowOrange;
}
.marker-orange path {
  fill: $orange;
}

// Override default variables before the import
@import "styles/constants.scss";
// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

body {
  color: #797c84;
  font-size: 0.75rem;
  background-color: #f9f9f9;
}

.homeChartWrap {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &.tall {
    min-height: 239px;
  }
}

.smallerChartWrap {
  min-height: 172px;
}

h2 {
  color: #797c84;
  font-size: 1rem;
  font-weight: 600;
}

.container {
  max-width: 970px;
}

.is-error {
  color: red;
}

label {
  text-transform: uppercase;
  font-size: 0.625rem;
  color: #797c84;
}

.btn-sm {
  font-size: 0.75rem;
}

select {
  color: #797c84;
  height: 2rem;
  padding: 0 20px 0 8px;
}

.row {
  margin-right: -10px;
  margin-left: -10px;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  padding-right: 10px;
  padding-left: 10px;
}

.legendDD {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 0;
  line-height: 1;

  &.small {
    font-size: 0.75rem;
  }

  &.large {
    font-size: 1.5rem;
  }

  &.xlarge {
    font-size: 2.5rem;
  }

  &.negative-value {
    color: $red !important;
  }
}

.legendDT {
  text-transform: uppercase;
  font-size: 0.625rem;
  font-weight: 500;
  margin-bottom: 10px;
}
.dl-lg {
  .legendDD {
    font-size: 1.25rem;
  }
}

.legend {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
  div {
    display: flex;
    align-items: center;
  }
  dd {
    margin: 0 10px 0 0;
    font-size: 0.625rem;
  }
  dt {
    margin-right: 5px;
  }
}

.boomboard-grid {
  display: grid;
  column-gap: 20px;
  row-gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(246px, 1fr));
}

.totalLg {
  position: absolute;
  width: 70px;
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  top: 40px;
  align-self: center;

  .total-count {
    font-size: 1.3125rem;
    color: $boomerangBlue;
    line-height: 1;
  }

  // .total-label {
  //   font-size: 0.5rem;
  //   color: $boomerangBlue;
  // }
  .total-label {
    font-size: 1rem;
    line-height: 1.25;
    font-weight: 500;
    text-align: center;
    color: $boomerangBlue;
    text-transform: capitalize;
  }
}

.link {
  cursor: pointer;
}

.clear-button {
  position: absolute;
  right: 30px;
  top: 3px;
  padding: 3px 5px;
  background-color: #fff;
}

@media print {
  .no-print {
    display: none !important;
  }
  body {
    background-color: transparent;
  }
  .site-header {
    border-bottom: 2px solid gray;
    box-shadow: none;
  }
  .navbar-brand {
    text-decoration: none;
  }
  .job-top-row {
    display: block;
  }
  .bpr-wrap,
  .bhr-wrap,
  .man-hours-wrap {
    width: 33%;
    display: inline-block;
  }
  .cashflow {
    text-align: center;
    width: 100%;
  }
  .print-section {
    border-bottom: 2px solid gray;
    padding-bottom: 20px;
    padding-top: 20px;
    margin-bottom: 20px;
  }
  .job-cost {
    width: 50%;
  }
  .invoice-print {
    padding: 0 20px;
  }
}

@import "./constants.scss";

.job-alert {
  position: relative;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: $shadow;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  padding: 12px 24px 12px 6px;

  &:hover {
    text-decoration: none;

    svg {
      transform: translateX(0) translateY(-50%);
    }
  }

  &.level-1 {
    border-left: 6px solid $yellow;
  }

  &.level-2 {
    border-left: 6px solid $yellowOrange;
  }

  &.level-3 {
    border-left: 6px solid $orange;
  }

  svg {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateX(-3px) translateY(-50%);
    transition: transform 250ms;
  }
}

.job-alert-type {
  font-size: 0.625rem;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  color: #797c84;
  font-weight: 600;
  text-transform: uppercase;
}

.job-alert-title {
  font-size: 0.75rem;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  color: #797c84;
}

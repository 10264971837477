@import "./constants.scss";

hr {
  margin: 0.5rem 0;
}

.total {
  position: absolute;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  top: 0;
}

.total-count {
  font-size: 1.3125rem;
  color: $boomerangBlue;
  line-height: 1;
}

.total-label {
  max-width: 50px;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1;
  color: $boomerangBlue;
  text-transform: capitalize;
}

.chart {
  position: relative;
}

@import "./constants.scss";

hr {
  margin: 0.5rem 0;
}

.total {
  position: absolute;
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  top: 0;
}

.total-count {
  font-size: 1.3125rem;
  color: $darkGray;
  line-height: 1;
}

.total-label {
  font-size: 0.5rem;
  color: $darkGray;
  text-transform: capitalize;
}

.chart {
  position: relative;
}

.chart-legend-wrapper {
  margin-top: -2.75rem;
}

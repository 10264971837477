@import "./constants.scss";

.chart-title {
  font-size: 1rem;
}

.firstJPRow {
  min-height: 190px;
}

.otherJPRow {
  min-height: 218px;
}

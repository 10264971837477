@import "./constants.scss";

.job-alerts {
  max-height: 300px;
  overflow-y: auto;
  margin: 0 -10px;
  padding: 25px 10px 0 10px;
}

.job-alerts-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.wrap {
  padding-left: 20px;
}

@import "./constants.scss";

.job-title {
  font-size: 16px;
}

.job-avg-wrapper {
  font-size: 24px;
  font-weight: 500;
  color: $gray;
  flex-grow: 1;
  display: flex;
  align-items: center;
  align-self: center;

  sup {
    font-size: 100%;
    top: -1em;
  }
}

.job-avg {
  font-size: 3.2rem;
  color: $boomerangBlue;
}

.bigNumber {
  font-size: 18px;
  .job-avg {
    font-size: 2.4rem;
  }
}

.reallyBigNumber {
  font-size: 16px;
  .job-avg {
    font-size: 2rem;
  }
}

$font-family-sans-serif: "Helvetica Neue", Helvetica, Arial, "Lucida Grande",
  sans-serif;

$boomerangBlue: #0071ce;
$darkBlue: #345d9d;
$blueGreen: #1bc4a6;
$darkBlueGreen: #16aa90;
$green: #34d27c;
$darkGreen: #2cb76b;
$yellow: #f3cb0d;
$yellowOrange: #f5a611;
$orange: #e98926;
$niceRed: #ea5644;
$red: #ff0000;

$gray: #b2b6be;

$secondary: #797c84;

$darkGray: #54565a;

$theme-colors: (
  "primary": $boomerangBlue,
  "secondary": $secondary,
);

$shadowLarge: 0 0 19px 2px rgba(0, 0, 0, 0.15);
$shadow: 0 0 10px 2px rgba(0, 0, 0, 0.15);

@import "./constants.scss";

.status-overall {
  @media (min-width: 480px) {
    grid-column: 1 / span 2;
  }
}

.h3 {
  font-size: 1rem;
}

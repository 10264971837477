@import "./constants.scss";

.app-header {
  box-shadow: $shadowLarge;
  min-height: 5.625rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
}

.h1 {
  font-size: 1.25rem;
  color: #797c84;
  font-weight: 600;
  margin: 0 0 0 12px;
}

.navBrand {
  display: flex;
  flex-direction: row;
  align-items: center;
}

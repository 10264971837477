@import "./constants.scss";

.logo {
  margin-bottom: 44px;
}

.submit {
  width: 100px;
  align-self: center;
  margin-top: 10px;
}

.footer {
  display: flex;
  justify-content: center;
}

.login-form-wrap {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}

.background {
  position: absolute;
  left: 0;
  bottom: 0;
}

.loginForm {
  width: 440px;
  border-radius: 4px;
  box-shadow: $shadow;
  border-top: 8px solid $boomerangBlue;
  background-color: #fff;
  margin-bottom: 118pxrr;
  padding: 42px 32px 34px;
}

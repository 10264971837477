@import "./constants.scss";

.personnel-wrapper {
  display: flex;
}

.personnel-column {
  display: flex;
  flex-direction: column;
  margin-right: 50px;
}

.position-title {
  font-size: 0.625rem;
  font-weight: 600;
  text-transform: uppercase;
}

.crew-wrapper {
  &:not(last-child) {
    &::after {
      content: ', ';
    }
  }
}

@import "./constants.scss";

.job-title {
  font-size: 16px;
}

.percent {
  font-size: 3.6rem;
  line-height: 0.9;
  margin-top: 18px;
}

.good {
  color: $blueGreen;
}

.bad {
  color: $niceRed;
}

.units {
  font-size: 1.6rem;
  color: $gray;
  position: relative;
  top: -1.1rem;
  margin-left: 0.2rem;
}

.label {
  text-transform: uppercase;
}

.secondary {
  display: flex;
  align-items: center;
}

.secondaryFigure {
  font-size: 1.2rem;
  font-weight: bold;
  margin-right: 4px;
}
